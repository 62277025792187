import React from "react"
import { graphql } from "gatsby"
import Layout from "./Layout"
import { Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import "swiper/css/navigation"
import { AdvancedImage } from "@cloudinary/react"
import { Cloudinary } from "@cloudinary/url-gen"
import { fill } from "@cloudinary/url-gen/actions/resize"
import { cloudImage, seoHead } from "../components/utils.js"
import GoogleMapReact from "google-map-react"
import HeroGeneric from "../components/locations/generic-hero"

export const query = graphql`
  query ($id: ID!) {
    wpgraphql {
      page(id: $id) {
        id
        title
        uri
        seo {
          title
          metaDesc
          opengraphTitle
          opengraphDescription
          opengraphUrl
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphImage {
            mimeType
            mediaDetails {
              file
            }
          }
        }
        featuredImage {
          node {
            mimeType
            mediaDetails {
              file
            }
            mimeType
          }
        }
        cfLocationSlider {
          locationAddress
          locationIntro
          locationLocation
          locationMainContent
          locationOpeningHours
          locationTelephoneLine
          locationSliderPics {
            teamSliderImage {
              databaseId
              altText
              mediaDetails {
                file
              }
            }
          }
        }
        cfMapData {
          mapGoogleMapLink
          mapLatitude
          mapLongitude
          mapZoomLevel
        }
      }
    }
  }
`

const PageTemplateLocationSingle = ({ data }) => {
  const page = data.wpgraphql.page
  const sliderItems = page.cfLocationSlider.locationSliderPics
  const heroBg = page.featuredImage.node.mediaDetails.file
  const heroBgType = page.featuredImage.node.mimeType
  const imgHeroURL = cloudImage(heroBg, heroBgType, undefined, true)
  const mapData = page.cfMapData

  // Gennerate the swiper slides

  const swiperItems = sliderItems.map(sliderItem => {
    const cld = new Cloudinary({
      cloud: {
        cloudName: process.env.GATSBY_CLOUDINARY_CLOUD,
      },
      url: {
        analytics: false,
      },
    })

    const dbId = sliderItem.teamSliderImage.databaseId
    const altText = sliderItem.teamSliderImage.altText
    const imgUri = sliderItem.teamSliderImage.mediaDetails.file
    const theImage = cld.image(`${process.env.GATSBY_API_FOLDER_URL}${imgUri}`)

    theImage.resize(fill().width(735).height(420))
    theImage.quality("70")
    theImage.format("auto")

    return (
      <SwiperSlide
        key={dbId}
        className={`swiper-slide d-flex align-items-end align-items-md-center`}
      >
        <AdvancedImage cldImg={theImage} alt={altText} className={`w-100`} />
      </SwiperSlide>
    )
  })

  // Generate the location marker

  const WarMarker = () => {
    return (
      <div>
        <a href={mapData.mapGoogleMapLink} target="_blank" rel="noreferrer">
          <img
            style={{
              zIndex: "9999999999",
              marginBottom: "8px",
              width: "30px",
              height: "30px",
            }}
            src="https://res.cloudinary.com/dvmdv4ttu/image/upload/w_100,h_100/assets/dev/map-marker_yuvizn.png"
            alt="waracle office location marker"
          />
          <span
            style={{
              color: "white",
              fontSize: "14px",
              background: "#1d1d1d",
              padding: "6px 10px",
              borderRadius: "7px",
              display: "inline-block",
              whiteSpace: "nowrap",
              fontWeight: "bold",
            }}
          >
            {page.title}
          </span>
        </a>
      </div>
    )
  }

  // Generate the map

  function GoogleMapContainer() {
    const apiKey = process.env.GATSBY_MAPS_API_KEY

    const mapContainerStyle = {
      width: "100%",
      height: "500px",
    }

    const center = {
      lat: mapData.mapLatitude,
      lng: mapData.mapLongitude,
    }

    console.log(mapData.mapLatitude)
    console.log(mapData.mapLongitude)

    return (
      <div style={mapContainerStyle} className="z_index_top">
        <GoogleMapReact
          bootstrapURLKeys={{ key: apiKey }}
          defaultCenter={center}
          defaultZoom={mapData.mapZoomLevel}
          options={{
            styles: [
              {
                featureType: "all",
                elementType: "labels.text.fill",
                stylers: [
                  {
                    saturation: 36,
                  },
                  {
                    color: "#000000",
                  },
                  {
                    lightness: 40,
                  },
                ],
              },
              {
                featureType: "all",
                elementType: "labels.text.stroke",
                stylers: [
                  {
                    visibility: "on",
                  },
                  {
                    color: "#000000",
                  },
                  {
                    lightness: 16,
                  },
                ],
              },
              {
                featureType: "all",
                elementType: "labels.icon",
                stylers: [
                  {
                    visibility: "off",
                  },
                ],
              },
              {
                featureType: "administrative",
                elementType: "geometry.fill",
                stylers: [
                  {
                    color: "#000000",
                  },
                  {
                    lightness: 20,
                  },
                ],
              },
              {
                featureType: "administrative",
                elementType: "geometry.stroke",
                stylers: [
                  {
                    color: "#000000",
                  },
                  {
                    lightness: 17,
                  },
                  {
                    weight: 1.2,
                  },
                ],
              },
              {
                featureType: "administrative",
                elementType: "labels.text.fill",
                stylers: [
                  {
                    color: "#8b9198",
                  },
                ],
              },
              {
                featureType: "landscape",
                elementType: "geometry",
                stylers: [
                  {
                    color: "#000000",
                  },
                  {
                    lightness: 20,
                  },
                ],
              },
              {
                featureType: "landscape",
                elementType: "geometry.fill",
                stylers: [
                  {
                    color: "#323336",
                  },
                ],
              },
              {
                featureType: "landscape.man_made",
                elementType: "geometry.stroke",
                stylers: [
                  {
                    color: "#414954",
                  },
                ],
              },
              {
                featureType: "poi",
                elementType: "geometry",
                stylers: [
                  {
                    color: "#000000",
                  },
                  {
                    lightness: 21,
                  },
                ],
              },
              {
                featureType: "poi",
                elementType: "geometry.fill",
                stylers: [
                  {
                    color: "#2e2f31",
                  },
                ],
              },
              {
                featureType: "road",
                elementType: "labels.text.fill",
                stylers: [
                  {
                    color: "#7a7c80",
                  },
                ],
              },
              {
                featureType: "road.highway",
                elementType: "geometry.fill",
                stylers: [
                  {
                    color: "#6e6e72",
                  },
                  {
                    lightness: 17,
                  },
                ],
              },
              {
                featureType: "road.highway",
                elementType: "geometry.stroke",
                stylers: [
                  {
                    color: "#202022",
                  },
                  {
                    lightness: 29,
                  },
                  {
                    weight: 0.2,
                  },
                ],
              },
              {
                featureType: "road.arterial",
                elementType: "geometry",
                stylers: [
                  {
                    color: "#000000",
                  },
                  {
                    lightness: 18,
                  },
                ],
              },
              {
                featureType: "road.arterial",
                elementType: "geometry.fill",
                stylers: [
                  {
                    color: "#393a3f",
                  },
                ],
              },
              {
                featureType: "road.arterial",
                elementType: "geometry.stroke",
                stylers: [
                  {
                    color: "#202022",
                  },
                ],
              },
              {
                featureType: "road.local",
                elementType: "geometry",
                stylers: [
                  {
                    color: "#000000",
                  },
                  {
                    lightness: 16,
                  },
                ],
              },
              {
                featureType: "road.local",
                elementType: "geometry.fill",
                stylers: [
                  {
                    color: "#393a3f",
                  },
                ],
              },
              {
                featureType: "road.local",
                elementType: "geometry.stroke",
                stylers: [
                  {
                    color: "#202022",
                  },
                ],
              },
              {
                featureType: "transit",
                elementType: "geometry",
                stylers: [
                  {
                    color: "#000000",
                  },
                  {
                    lightness: 19,
                  },
                ],
              },
              {
                featureType: "water",
                elementType: "geometry",
                stylers: [
                  {
                    color: "#000000",
                  },
                  {
                    lightness: 17,
                  },
                ],
              },
              {
                featureType: "water",
                elementType: "geometry.fill",
                stylers: [
                  {
                    color: "#454851",
                  },
                ],
              },
            ],
          }}
        >
          <WarMarker lat={mapData.mapLatitude} lng={mapData.mapLongitude} />
        </GoogleMapReact>
      </div>
    )
  }

  return (
    <>
      <Layout>
        <HeroGeneric pagedata={page} imghero={imgHeroURL} />
        <div className="z_index_top grey_bg pt-6 pb-6">
          <div className="container">
            <div className="row align-items-center color-white">
              <div className="col-12 col-lg-5 mb-4">
                <h3 className="h4 mb-3">
                  {page.cfLocationSlider.locationLocation}
                </h3>
                <h2
                  dangerouslySetInnerHTML={{
                    __html: page.cfLocationSlider.locationIntro,
                  }}
                  className="mb-4 mb-lg-5"
                />
                <h3
                  dangerouslySetInnerHTML={{
                    __html: page.cfLocationSlider.locationAddress,
                  }}
                  className="h4 mb-3"
                />
                <h3 className="h4 mb-3">
                  {page.cfLocationSlider.locationOpeningHours}
                </h3>
                <h3 className="h4">
                  {page.cfLocationSlider.locationTelephoneLine}
                </h3>
              </div>
              <div className="col-12 col-lg-7 single-locations-swiper">
                <Swiper
                  modules={[Navigation]}
                  navigation
                  spaceBetween={0}
                  slidesPerView={1}
                  className={`swiper location_swiper`}
                >
                  {swiperItems}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
        <div className="z_index_top grey_bg pb-6">
          <div className="container">
            <div className="row">
              <div className="text_center color-white">
                <h3
                  dangerouslySetInnerHTML={{
                    __html: page.cfLocationSlider.locationMainContent,
                  }}
                  className="w-75 mx-auto"
                />
              </div>
            </div>
          </div>
        </div>
        {GoogleMapContainer()}
      </Layout>
    </>
  )
}

export default PageTemplateLocationSingle

// Metadata in head from gatsby ^v4.19.0
// replaces helmet which wasn't generating the tags statically

export const Head = ({ data }) => {
  const page = data.wpgraphql.page
  const seoTags = seoHead(page.seo, page.uri)
  return seoTags
}

import React from "react"
import * as style from "./generic-hero.module.css"

const HeroGeneric = ({ pagedata, imghero }) => {
  const locHero = pagedata.cfLocationSlider.locationLocation || "generic"

  console.log(locHero)

  let bgImg = ""
  let fgImg = ""

  switch (pagedata.title) {
    case "Waracle Edinburgh":
      bgImg =
        "https://res.cloudinary.com/dvmdv4ttu/image/upload/f_auto/img/edinburgh-top-layer_icdp9e.webp"
      fgImg =
        "https://res.cloudinary.com/dvmdv4ttu/image/upload/f_auto/img/edinburgh-location-2_sdsccc.webp"
      break
    case "Waracle Glasgow":
      bgImg =
        "https://res.cloudinary.com/dvmdv4ttu/image/upload/f_auto/img/glasgow-top-layer_zqdfem.webp"
      fgImg =
        "https://res.cloudinary.com/dvmdv4ttu/image/upload/f_auto/img/glasgow-location_kxtc5z.webp"
      break
    case "Waracle London":
      bgImg =
        "https://res.cloudinary.com/dvmdv4ttu/image/upload/f_auto/img/London-top-layer_phfzqo.webp"
      fgImg =
        "https://res.cloudinary.com/dvmdv4ttu/image/upload/f_auto/img/London-location_bdbyig.webp"
      break
    case "Waracle Dundee":
      bgImg =
        "https://res.cloudinary.com/dvmdv4ttu/image/upload/f_auto/img/dundee-top-layer_f2opec.webp"
      fgImg =
        "https://res.cloudinary.com/dvmdv4ttu/image/upload/f_auto/img/dundee-location_rixdr4.webp"
      break
    default:
      bgImg = ""
      fgImg = ""
  }

  if (locHero !== "generic") {
    return (
      <div
        className={`d-flex justify-items-center ${style.location_container} overflow-hidden`}
      >
        <div className="col-12">
          <div
            id="hide-row-when-nav-open"
            className={`row h-100 text_center color-white ${style.location_title}`}
          >
            <h1
              id="text-fade-out"
              className={`location header-fade-in position-fixed top-25 start-0 ${style.z_1}`}
            >
              {pagedata.title}
            </h1>
          </div>
        </div>
        <div
          className={`${style.location_layer} bg_image ${style.z_2}`}
          style={{ backgroundImage: "url(" + bgImg + ")" }}
        ></div>
        <div
          className={`${style.location_layer} bg_image ${style.z_0}`}
          style={{ backgroundImage: "url(" + fgImg + ")" }}
        ></div>
        <div className={style.gradient_image_overly}></div>
      </div>
    )
  } else {
    // default to original if issues

    return (
      <div
        className="d-flex align-items-center darkgrey_bg bg_image flex-column justify-items-center"
        style={{
          backgroundImage: imghero,
          height: "100vh",
        }}
      >
        <div id="hide-row-when-nav-open" className="flex-grow-1">
          <div className="row h-100 align-content-center z_index_top text_center color-white">
            <h1 className="location header-fade-in">{pagedata.title}</h1>
          </div>
        </div>
        <div className="gradient_image_overly"></div>
        <div className="dark_image_overly fade-out"></div>
      </div>
    )
  }
}

export default HeroGeneric
